/*
HEXCLOCK
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jun 7, 2022
UPDATE DATE: Jun 7, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: HexClock | Time in Color!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Thin.woff2') format('woff2'),
       url('./assets/font/Exo-Thin.woff') format('woff'),
       url('./assets/font/Exo-Thin.ttf') format('ttf'),
       url('./assets/font/Exo-Thin.eot') format('eot'),
       url('./assets/font/Exo-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

.clbg{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;

  .cltx{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 70px 0 75px 0;
    color: #FFF;
    text-align: center;
    font-size: 14vw;
    line-height: 14vw;
    font-weight: 100;

    > div{
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
      margin: 0 0 20px 0;

      > span{
        font-size: 16px;
      }
    }

    > strong{
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 30px 0;
    }

    ul{
      // display: block;
      margin: -5px;
      // padding: 20px 0 0 0;
      font-size: 10px;
      line-height: 14px;
      overflow: auto;

      li{
        float: left;
        padding: 5px;
        font-weight: bold;
        text-align: left;
        white-space: nowrap;
        box-sizing: border-box;

        span{
          display: block;
          font-weight: normal;
        }

        .colorname{
          display: inline-block;
          vertical-align: middle;
          margin: -1px 3px 0 0;
          width: 9px;
          height: 9px;
          border: 1px solid rgba(255, 255, 255, .5);
        }

        .chart{
          height: 15px;
          background: rgba(255, 255, 255, .5);
          padding: 1px;
          margin: 5px 0 0 0;
          box-sizing: border-box;
          
          > span{
            position: relative;
            display: block;
            height: 13px;
            transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -webkit-transition: all 200ms ease-in-out;

            .pointer{
              position: absolute;
              top: -4px;
              bottom: -4px;
              width: 1px;
              background: #FFF;
              transform: translate(-.5px, 0);
              transition: all 200ms ease-in-out;
              -moz-transition: all 200ms ease-in-out;
              -webkit-transition: all 200ms ease-in-out;
            }

            &.huepicker{
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,ffff00+17,00ff00+33,00ffff+50,0000ff+67,ff00ff+83,ff0000+100 */
              background: rgb(255,0,0); /* Old browsers */
              background: -moz-linear-gradient(left,  rgba(255,0,0,1) 0%, rgba(255,255,0,1) 17%, rgba(0,255,0,1) 33%, rgba(0,255,255,1) 50%, rgba(0,0,255,1) 67%, rgba(255,0,255,1) 83%, rgba(255,0,0,1) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(left,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to right,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 */
            }

            &.darkpicker{
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
              background: rgb(0,0,0); /* Old browsers */
              background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            }
          }

          &.multi{
            > span{
              display: inline-block;
            }
          }
        }

        &.lay2{
          width: 50%;
        }

        &.lay3{
          width: 33.33%;
        }

        &.lay4{
          width: 25%;
        }
        
        &.layfull{
          width: 100%;
        }

        &.clear{
          clear: both;
        }
      }
    }

    > ul{
      margin: -5px 40px;
      background: rgba(0, 0, 0, .25);
      padding: 15px;
    }
  }
}

header{
  position: fixed;
  z-index: 999;
  padding: 15px 20px;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .05);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  > a{
    display: block;
    color: #DDD;
    height: 40px;
    width: 164px;
    margin: 0 auto;
    
    &:hover{
      color: #FFF;
    }

    > svg{
      display: block;
      height: 40px;
      width: 164px;
    }
  }
}

footer{
  bottom: 0;
  font-size: 11px;
  left: 0;
  line-height: 15px;
  padding: 20px 40px 40px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  color: #BBB;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;
    color: #DDD;

    &:hover{
      margin: 0 5px;
      color: #FFF;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){
  .clbg{
    position: relative;

    .cltx{
      position: relative;
      // height: 100vh;
      transform: translate(0, 0);
      box-sizing: border-box;

      > div{
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){
  .clbg{
    .cltx{
      padding: 90px 0 75px 0;
      font-size: 10vw;
      line-height: 10vw;

      > strong{
        font-size: 10px;
        line-height: 14px;
      }

      ul{
        li{
          &.lay2{
            width: 100%;

            &.same{
              width: 50%;
            }
          }
        }
      }
    }
  }

  footer{
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  .clbg{
    .cltx{
      padding: 84px 0 90px 0;
      font-size: 20vw;
      line-height: 20vw;

      > strong{
        font-size: 14px;
        line-height: 18px;
        margin: 5px 0 25px 0;
      }

      ul{
        li{
          &.lay2,
          &.lay3,
          &.lay4{
            width: 100%;
          }
        }
      }
      
      > ul{
        margin: -5px 20px;
        background: rgba(0, 0, 0, .25);
        padding: 15px;
      }
    }
  }

  header{
    > a{
      height: 24px;
      width: 113px;
  
      > svg{
        height: 24px;
        width: 113px;
      }
    }
  }

  footer{
    padding: 20px;
  }
}